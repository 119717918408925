@keyframes exitRightFromScreen {
  0% {
    margin-right: 0vw;
  }

  100% {
    margin-right: -100vw;
    opacity: 0;
  }
}
