@keyframes exitDownFromScreen {
  0% {
    margin-top: 0vh;
    opacity: 1;
  }

  100% {
    margin-top: 150vh;
    opacity: 0;
  }
}
