@keyframes iPhoneModalEnter {
  0% {
    background-color: #00000000;
    height: 150vh;
  }

  100% {
    background-color: #00000099;
    height: 100vh;
    bottom: 0px;
  }
}

@keyframes iPhoneModalExit {
  0% {
    background-color: #00000099;
    height: 100vh;
  }

  100% {
    background-color: #00000000;
    height: 200vh;
  }
}

@keyframes iPadModalEnter {
  0% {
    height: 0px;
  }

  100% {
    height: 350px;
  }
}

@keyframes iPadModalExit {
  0% {
    height: 350px;
  }

  100% {
    background-color: #00000000;
    height: 0px;
  }
}
