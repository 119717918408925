@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Raleway/Raleway.ttf');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  src: url('../../assets/fonts/Raleway/Raleway-italic.ttf');
}
