@keyframes float {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-3px);
  }

  75% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(0px);
  }
}
