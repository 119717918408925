@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-25px);
  }

  30% {
    transform: translateX(-12px);
  }

  35% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(25px);
  }

  50% {
    transform: translateX(0px);
  }

  60% {
    transform: translateX(-5px);
  }

  70% {
    transform: translateX(15px);
  }

  80% {
    transform: translateX(-2px);
  }

  90% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(0px);
  }
}
